<template>
  <div>
    <!-- 头部部分 -->
    <div class="titlebox">
      <div class="title">销售员管理</div>
      <div>
        <el-row :gutter="20">
          <el-col :span="16">
            <el-input
              placeholder="输入商品型号搜索"
              prefix-icon="el-icon-search"
              v-model="limit.goods_type"
            >
            </el-input>
          </el-col>
          <el-col :span="4"
            ><el-button class="addButton-class" @click="searchClick"
              >搜索</el-button
            ></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 表格部分 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      element-loading-text="拼命加载中"
      element-loading-background="#fff"
      border
    >
      <el-table-column
        prop="warehouse_name"
        label="商场名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="no"
        label="商品图片"
        align="center"
      >
      <template scope="scope">
          <el-image :src="scope.row.goods_url | replaceUrl"></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_type"
        label="型号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_brand"
        label="品牌"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_norms"
        label="规格"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_class"
        label="类别"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_color"
        label="颜色"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_price"
        label="单价"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sell_num"
        label="数量"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sell_price"
        label="金额"
        align="center"
      ></el-table-column>
      <el-table-column label="实售金额" prop="sell_price" align="center">
      </el-table-column>
      <el-table-column
        prop="reward"
        label="奖金"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sell_name"
        label="销售员工"
        align="center"
      ></el-table-column>
      <!-- <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="delClick(scope.row.read)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <!-- 分页 -->
    <el-pagination
      class="table-limit"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageArray"
      :page-size="this.limit.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { saleuser } from "@/api/salesman.js";
export default {
  data() {
    return {
      pageArray:this.$store.state.pageArray,
      limit: {
        page: 1,
        page_size: 10,
        goods_type: "",
      },
      total: 0,
      loading: true,
      currentPage: 1,
      tableData: [],
    };
  },
  created() {
    // 获取首页数据
    this.getinfo();
  },
  methods: {
    //获取列表信息
    getinfo() {
      saleuser(this.limit).then((res) => {
        let data = res.data.data;
        this.tableData = data;
        console.log(this.tableData);
        this.total = res.data.total;
        this.loading = false;
      });
    },
    // 点击搜索
    searchClick(){
        this.limit.page = 1
        this.getinfo()
    },
    //分页
    handleSizeChange(val) {
      this.limit.page_size = val;
      this.loading = true;
      this.getinfo();
    },
    handleCurrentChange(val) {
      this.limit.page = val;
      this.loading = true;
      this.getinfo();
    },
  },
};
</script>
<style scoped>
.titlebox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.titlebox .title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}
.titlebox .addButton-class {
  background: #2290ff;
  color: #fff;
  border: none;
}
.chakan {
  color: #2290ff;
}
</style>
<style>
.el-table--border:after,.el-table--group:after,.el-table:before {background-color:  #000;}
.el-table--border,.el-table--group {  border-color: #000;}
.el-table td,.el-table th.is-leaf { border: 2px solid  #000;}
.el-table--border th,.el-table--border th.gutter:last-of-type {border: 2px solid  #000;}
.el-table--border td,.el-table--border th {border: 2px solid  #000;}
.el-table__footer-wrapper{border:  #000 solid 2px;}
</style>